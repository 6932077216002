<template>
  <div class="edit-nest-swiper-box">

    <div class="cell-b">
      <selfCell title="背景圖">
        <selfUpload
          v-model="configs.bg"
          type="default"
          @change="setValue('bg')"
        />
      </selfCell>
    </div>

    <div
      v-if="type == 'title'"
      class="cell-b"
    >
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        />
      </selfCell>
      <selfCell
        title="描述"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          type="textarea"
          rows="5"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        />
      </selfCell>
    </div>

    <!-- 輪播結構 -->

    <!-- 操作 -->
    <selfCell title="操作">
      <el-button-group>
        <el-tooltip
          class="item"
          effect="dark"
          content="上一個"
          placement="top"
        >
          <el-button
            type="primary"
            icon="el-icon-arrow-left"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="下一個"
          placement="top"
        >
          <el-button
            type="primary"
            icon="el-icon-arrow-right"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="新增"
          placement="top"
        >
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addItem"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="刪除"
          placement="top"
        >
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="removeItem"
          ></el-button>
        </el-tooltip>
      </el-button-group>
    </selfCell>
    <!-- 標題 -->
    <selfCell title="圖標">
      <selfUpload
        v-model="configs.items[index].bigIcon"
        type="default"
        @change="setValue('items')"
        style="width:50px"
      />
      <selfUpload
        v-model="configs.items[index].icon"
        type="default"
        @change="setValue('items')"
        style="width:50px"
      />
    </selfCell>
    <div class="cell-b">
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs.items[index][`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue('items')"
        />
      </selfCell>
    </div>
    <div class="cell-b">
      <selfCell
        title="描述"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs.items[index][`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          type="textarea"
          rows="5"
          @input="setValue('items')"
        />
      </selfCell>
    </div>
    <!-- 圖片組 -->
    <div class="cell-b">
      <h4>圖片</h4>
      <div
        v-for="(item, mindex) in configs.items[index].imgs"
        :key="mindex"
        class="cell-b"
      >
        <el-button-group class="btn-group">
          <el-button
            type="primary"
            icon="el-icon-upload2"
            :disabled="mindex == 0"
            size="mini"
            @click="itemCheck(mindex,mindex-1)"
          ></el-button>
          <el-button
            type="success"
            icon="el-icon-download"
            :disabled="mindex == configs.items[index].imgs.length-1"
            size="mini"
            @click="itemCheck(mindex,mindex+1)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="configs.items[index].imgs.length == 1"
            @click="itemDel(mindex)"
          ></el-button>
          <el-button
            type="warning"
            icon="el-icon-plus"
            size="mini"
            :disabled="configs.items[index].imgs.length == 5"
            @click="itemAdd(mindex)"
          ></el-button>
        </el-button-group>
        <selfUpload
          v-model="item.img"
          type="default"
          @change="setValue('items')"
        />
      </div>

      <!-- <selfUpload
        v-model="configs.items[index].imgs[configs.items[index].imgs.length].img"
        type="default"
        @change="setValue('items')"
      /> -->
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editModuleMixin from "../../mixins/editModuleMixin"
import { getByName } from '@/js/utils/default_config';

export default {
  name: "edit-nest-swiper",
  mixins: [langEditMixin, editModuleMixin],
  methods: {
    addItem () {
      let swiper = getByName('nestSwiper')
      let item = swiper.configs.items[0]
      this.$emit('addItem', { index: this.index, item: item, key: 'items' })
    },
    removeItem () {
      this.$emit('delItem', { index: this.index, key: 'items' })
    },
    itemCheck (index, newIndex) {
      let items = this.configs.items
      let imgs = items[this.index].imgs;
      let _ = imgs.splice(index, 1);
      imgs.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items
      let imgs = items[this.index].imgs;
      imgs.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items
      let imgs = items[this.index].imgs;
      imgs.splice(index + 1, 0, this.$util.object.clone(imgs[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
</style>